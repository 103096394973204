import Header from "src/layouts/dashboard/header";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import GitHubIcon from '@mui/icons-material/GitHub';
import EmailIcon from '@mui/icons-material/Email';
import { useEffect, useState } from 'react';
import './homepage.css';
import { PieChart } from '@mui/x-charts/PieChart';
import Timeline from "./Timeline";
import { Grid, Card } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import OpenSourceProjects from "./OpenSourceProjects";

const frontendSkills = [
    'HTML',
    'CSS',
    'JavaScript',
    'React.js',
    'Basic UI / UX Design'
];

const backendSkills = [
    'Java / Spring Boot',
    'RESTful API Dev',
    'MySQL',
    'Git Version Control',
    'CI / CD'
];

const skillSets = [
    {
        "title" : "Languages & Frameworks",
        "skills" : [
            {
                "name" : 'Java',
                "icon" : "/assets/icons/Skills/Languages/icons8-java-48.png",
                "source" : "https://icons8.com/icon/13679/java",
            },
            {
                "name" : 'Spring Boot',
                "icon" : "/assets/icons/Skills/Languages/icons8-spring-boot-48.png",
                "source" : "https://icons8.com/icon/90519/spring-boot",
            },
            {
                "name" : 'JavaScript',
                "icon" : "/assets/icons/Skills/Languages/icons8-javascript-48.png",
                "source" : "https://icons8.com/icon/PXTY4q2Sq2lG/javascript",
            },
            {
                "name" : 'React',
                "icon" : "/assets/icons/Skills/Languages/icons8-react-native-48.png",
                "source" : "https://icons8.com/icon/123603/react-native",
            },
            {
                "name" : 'Elasticsearch',
                "icon" : "/assets/icons/Skills/Languages/icons8-elasticsearch-48.png",
                "source" : "https://icons8.com/icon/aGBLcugRkYpT/elasticsearch"
            },
            {
                "name" :'Python',
                "icon" : "/assets/icons/Skills/Languages/icons8-python-48.png",
                "source" : "https://icons8.com/icon/13441/python",
            },
            {
                "name" : 'Flask',
                "icon" : "/assets/icons/Skills/Languages/icons8-flask-48.png",
                "source" : "https://icons8.com/icon/TtXEs5SeYLG8/flask",
            },
            {
                "name" : 'MySQL',
                "icon" : "/assets/icons/Skills/Languages/icons8-mysql-48.png",
                "source" : "https://icons8.com/icon/UFXRpPFebwa2/mysql-logo",
            },
            {
                "name" :'Redis',
                "icon" : "/assets/icons/Skills/Languages/icons8-redis-48.png",
                "source" : "https://icons8.com/icon/pHS3eRpynIRQ/redis",
            },
            {
                "name" : 'RabbitMQ',
                "icon" : "/assets/icons/Skills/Languages/rabbitmq.png",
                "source" : "https://iconduck.com/icons/27855/rabbitmq",
            },
  
        ]
    },
    {
        "title" : "DevOps & Tools",
        "skills" : [
            {
                "name" : 'Git',
                "icon" : "/assets/icons/Skills/DevOps/icons8-git-48.png",
                "source" : "https://icons8.com/icon/20906/git",
            },
            {
                "name" : 'Docker',
                "icon" : "/assets/icons/Skills/DevOps/icons8-docker-48.png",
                "source" : "https://icons8.com/icon/22813/docker",
            },
            {
                "name" : 'CI/CD',
                "icon" : "/assets/icons/Skills/DevOps/icons8-continuous-integration-64.png",
                "source" : "https://icons8.com/icon/AepDd8ZTdbUE/continuous-integration",
            },
            {
                "name" : 'Jenkins',
                "icon" : "/assets/icons/Skills/DevOps/icons8-jenkins-48.png",
                "source" : "https://icons8.com/icon/39292/jenkins",
            },
            {
                "name" : 'GitHub Actions',
                "icon" : "/assets/icons/Skills/DevOps/icons8-github-48.png",
                "source" : "https://icons8.com/icon/106562/github",
            },
            {
                "name" : 'Cloudflare',
                "icon" : "/assets/icons/Skills/DevOps/Cloudflare.png",
                "source" : "https://icons8.com/icon/fUGx53gD9Jof/cloudflare-provides-content-delivery-network-services%2C-ddos-mitigation."
            },
            {
                "name" : 'Linux',
                "icon" : "/assets/icons/Skills/DevOps/icons8-linux-48.png",
                "source" : "https://icons8.com/icon/17842/linux",
            },
            {
                "name" : 'Nginx',
                "icon" : "/assets/icons/Skills/DevOps/icons8-nginx-48.png",
                "source" : "https://icons8.com/icon/t2x6DtCn5Zzx/nginx"
            },
            {
                "name" : 'Markdown',
                "icon" : "/assets/icons/Skills/DevOps/markdown.png",
                "source" : "https://icons8.com/icon/121792/markdown",
            },
            {
                "name" : 'Alibaba Cloud',
                "icon" : "/assets/icons/Skills/DevOps/alibaba-cloud-logo.svg",
                "source" : "https://github.com/mcsrainbow/alibaba-cloud-icons/tree/main",
            },
            {
                "name" : 'Tencent Cloud',
                "icon" : "/assets/icons/Skills/DevOps/tencent-cloud-logo.png",
                "source" : "https://iconduck.com/icons/158891/tencentcloud",
            },  
            
        ]
    },
    {
        "title" : "Soft Skills",
        "skills" : [
            {
                "name" : 'Communication',
                "icon" : "/assets/icons/Skills/SoftSkills/icons8-communication-64.png",
                "source" : "https://icons8.com/icon/64478/communication",
            },  
            {
                "name" : 'Problem-Solving',
                "icon" : "/assets/icons/Skills/SoftSkills/icons8-problem-solving-100.png",
                "source" : "https://icons8.com/icon/OrDsjdtZnvUU/problem-solving",
            },  
            {
                "name" : 'Teamwork',
                "icon" : "/assets/icons/Skills/SoftSkills/teamwork-logo.png",
                "source" : "https://icons8.com/icon/lYMUAFBCOd5o/meeting-room",
            },  
            {
                "name" : 'Leadership',
                "icon" : "/assets/icons/Skills/SoftSkills/team-leader.png",
                "source" : "https://www.flaticon.com/free-icons/leader",
            },  

        ]
    }
];

const outsideCodingInterests = [
	{
		"title" : "Travel",
		"image" : "/assets/images/travel-cover2.png",
		"description" : "Rogers loves travelling. He wants to get in touch with nature on a personal level and get closer to Mother Earth, the only place that we call home in the universe."
	},
	{
		"title" : "Food",
		"image" : "/assets/images/food-cover.png",
		"description" : "Roger can't resist hotpot in winter and iced coffee in summer. To Roger, eating is something that brings warmth in the cold and refreshment in the heat, making each season more enjoyable."
	},
	{
		"title" : "Music",
		"image" : "/assets/images/music-cover.png",
		"description" : "Music is just another way of connecting with people. Roger enjoys the rhythms, emotions and stories behind each piece."
	},
];



export default function HomePage() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 650);

    useEffect(() => {
        const handleResize = () => {
          setIsMobile(window.innerWidth <= 650);
        };
    
        // Add event listener for window resize
        window.addEventListener('resize', handleResize);
    
        // Clean up the event listener when the component unmounts
        return () => {
          window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
            <Header hideMenuIcon="true" active={0}/>
            <div style={{
                // border:"solid red 1px",
                display:"flex",
                flexDirection:"column",
                justifyContent:"center",
                alignItems:"center",
            }}>
                {/* home page background color */}
                <div style={{
                    // backgroundImage: "url(/assets/images/homebackimg.png)",
                    // backgroundSize: "cover", // cover the entire div
                    // backgroundPosition: "center",
                    // backgroundRepeat: "no-repeat",
                    background: 'linear-gradient(to bottom, #e7bca3, #eed4c9, #f7e4ce, #f9fafb)', // Gradient background
                    // border:"solid green 2px",
                    height: "100vh",
                    width:"100%",
                    display:"flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent:"center",
                    fontWeight:"bolder",
                    fontSize: "23px",
                }}>
                    Write Code | Code Life. 
                    <ExpandMoreIcon style={{marginTop:"10px", marginLeft:"13px"}}/>
                </div>

                {/* About me section */}
                <div style={{
                    maxWidth:"1200px",
                    padding:"8px 14px",
                    // border:"solid yellow 2px",
                    display:'flex',
                    flexDirection: isMobile ? 'column' : 'row',
                    alignItems:'center',
                    justifyContent:'center'
                }}>

                    {/* Profile Img */}
                    <div className="profile-img-1" style={{
                        border:'solid #dbdbdb 1px',
                        margin: isMobile ? '-30px 10px 10px 10px' : '10px',
                        display: isMobile ? 'inline-flex' : "none",
                        alignItems:'center',
                        justifyContent:'center',
                        backgroundColor: '#dbdbdb',
                        // backgroundImage: 'url(/assets/images/rogershi.png)',
                        // backgroundSize: 'cover', // ensures the img covers the div
                        // backgroundPosition: 'center',
                        // backgroundRepeat: 'no-repeat',
                        width:"155px",
                        height:"155px",
                        borderRadius:"50%",
                        overflow: 'hidden',
                        position: 'relative',
                        flexShrink: 0, // Prevents the image div from shrinking on desktop devices
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)', // Soft shadow
                    }}>
                        <img src="/assets/images/rogershi.png" style={{position:'absolute', top:'-20px', left:'-10px'}} />
                        
                        {/* <img 
                            src="/assets/images/rogershi.png" 
                            style={{
                                border:"solid red 1px",
                                borderRadius: '50%',
                                width: '300px',
                                height: '300px',
                                objectFit: 'cover'
                            }}
                        /> */}
                    </div>

                    {/* Brief Intro */}
                    <div style={{
                        // border:'solid brown 2px', 
                        padding:'2px'}}>
                        <p style={{
                            fontSize:"23px",
                            fontWeight: "bolder",
                        }}>Roger Shi</p>

                        <p style={{color:"#7b7878", fontSize:"18px"}}>
                            Java software engineer based in Beijing, China, with a strong focus on building scalable and robust applications.
                        </p>
                        <p> 
                            He wants to utilize his expertise to develop solutions that drive business success and optimize system performance.
                            He wishes to continually refine his skills, staying updated with the latest advancements in Java and related tech stacks.
                            He desires to work on projects that challenge his abilities and contribute to meaningful technological advancements.
                        </p>

                        <div style={{
                            display:"flex",
                            // border: 'solid red 2px',
                            gap:'10px'
                        }}>
                            <LinkedInIcon 
                                style={{
                                    cursor:"pointer", 
                                    // border:"solid black 1px",
                                    fontSize:'33px',
                                    color:'#0873af'
                                }} 
                                onClick={() => window.open('https://www.linkedin.com/in/roger-shi-a14b33256', '_blank')}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.transition = 'transform 0.5s ease';
                                    e.currentTarget.style.transform = 'scale(1.08)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.transition = 'transform 0.5s ease';
                                    e.currentTarget.style.transform = 'scale(1)';
                                }}
                            />
                            <GitHubIcon 
                                style={{
                                    cursor:"pointer", 
                                    // border:"solid black 1px",
                                    fontSize:'33px'
                                }} 
                                onClick={() => window.open('https://github.com/rogershi-dev', '_blank')}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.transition = 'transform 0.5s ease';
                                    e.currentTarget.style.transform = 'scale(1.08)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.transition = 'transform 0.5s ease';
                                    e.currentTarget.style.transform = 'scale(1)';
                                }}
                            />
                            <EmailIcon 
                                style={{
                                    cursor: 'pointer',
                                    // border: 'solid black 1px',
                                    fontSize: '33px',
                                    color: '#d6ab92'
                                }}
                                onClick={() => window.location.href='mailto:rogershidev@gmail.com'}
                                onMouseEnter={(e) => {
                                    e.currentTarget.style.transition = 'transform 0.5s ease';
                                    e.currentTarget.style.transform = 'scale(1.08)';
                                }}
                                onMouseLeave={(e) => {
                                    e.currentTarget.style.transition = 'transform 0.5s ease';
                                    e.currentTarget.style.transform = 'scale(1)';
                                }}
                            />
                        </div>
                    </div>

                    {/* Profile Img */}
                    <div className="profile-img-2" style={{
                        border:'solid #dbdbdb 1px',
                        margin: isMobile ? '-30px 10px 10px 10px' : '10px',
                        display: isMobile ? "none" : 'inline-flex',
                        alignItems:'center',
                        justifyContent:'center',
                        backgroundColor: '#dbdbdb',
                        // backgroundImage: 'url(/assets/images/rogershi.png)',
                        // backgroundSize: 'cover', // ensures the img covers the div
                        // backgroundPosition: 'center',
                        // backgroundRepeat: 'no-repeat',
                        width:"155px",
                        height:"155px",
                        borderRadius:"50%",
                        overflow: 'hidden',
                        position: 'relative',
                        flexShrink: 0, // Prevents the image div from shrinking on desktop devices
                        boxShadow: '0px 4px 15px rgba(0, 0, 0, 0.3)', // Soft shadow
                    }}>
                        <img src="/assets/images/rogershi.png" style={{position:'absolute', top:'-20px', left:'-10px'}} />
                        
                        {/* <img 
                            src="/assets/images/rogershi.png" 
                            style={{
                                border:"solid red 1px",
                                borderRadius: '50%',
                                width: '300px',
                                height: '300px',
                                objectFit: 'cover'
                            }}
                        /> */}
                    </div>
                    
                </div>



                {/* Skill sets */}
                <div style={{
                    // border: "solid red 1px", 
                    fontWeight:"bolder", 
                    fontSize:"23px", 
                    margin:"100px 0px 30px 0px"
                    }}>Skill Sets</div>
                
                <div className="skill-sets-container">
                    <Grid container spacing={3}>
                        {
                            skillSets.map((skillSet, index) => (
                                <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                                    <Card className="skill-sets-desc" >
                                            <p style={{fontWeight:'bold', fontSize:"22px"}}>{skillSet.title}</p>
                                            
                                            {
                                                skillSet.skills.map((skill, index) => (
                                                    <div key={index} className="skill-icon-container">
                                                        
                                                        <a target="_blank" href={skill.source}>
                                                            <div className="skill-icon-container-img">
                                                                <img 
                                                                    src={skill.icon} 
                                                                    // style={{width: "80%", height: "80%"}}
                                                                />
                                                                
                                                            </div>
                                                        </a>
                                                         
                                                        <div style={{fontWeight:"bold"}}>{skill.name}</div>
                                                    </div>
                                                ))
                                            }
                                            
                                    </Card>
                                </Grid>
                                
                            ))
                        }

                    </Grid>
                    {/* <div className="skill-sets-piechart">
                        <PieChart
                            series={[
                                {
                                    data: [
                                        {id: 'Backend', label: 'Backend', value: 80, color: '#585454' },
                                        {id: 'Frontend', label: 'Frontend', value: 20, color: '#dbdbdb' }
                                    ],
                                    innerRadius: 20,
                                    outerRadius: 100,
                                    paddingAngle: 5,
                                    cornerRadius: 15,
                                    startAngle: -90,
                                    endAngle: 280,
                                    // cx: 250,
                                    // cy: 250,
                                }
                            ]}
                        />
                    </div> */}

                    {/* <div className="skill-sets-desc-container">
                    {
                        skillSets.map((skillSet, index) => (
                            <div className="skill-sets-desc" key={index}>
                                <div>
                                    <p style={{fontWeight:'bolder'}}>{skillSet.title}</p>
                                    <div>
                                        {
                                            skillSet.skills.map((skill, index) => (
                                                <div key={index}>
                                                    {skill}
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                        
                    </div> */}
                </div>

                {/* Open Source Projects */}
                <OpenSourceProjects />

                {/* Life outside coding */}
                <div className="life-outside-coding-container" >
                    <div style={{
                            fontSize:"23px",
                            fontWeight: "bolder",
                            // marginTop: '100px'
                            marginBottom: '30px'
                        }}>
                            Life Outside Coding
                    </div>
                    <Grid container spacing={3}>
                        {
                            outsideCodingInterests.map((interest, index) => (
                                <Grid item xs={12} sm={6} md={6} lg={4} key={index}>
                                    <Card className="outside-coding-thing" >
                                        <p style={{fontWeight:"bolder"}}>{interest.title}</p>
                                        <div className='mini-cover-img' style={{backgroundColor: "#324b59"}}>
                                            <img src={interest.image}/>
                                        </div>
                                        <p>{interest.description}</p>
                                    </Card>
                                </Grid>
                            ))
                        }
                    </Grid>
                </div>

                {/* Timeline */}
                
                <Timeline />

                {/* Footer */}
                <div className="footer">
                    LOVE 
                    {/* <span><FavoriteIcon /></span>  */}
                    <span></span>
                    FROM ROGER
                </div>
                {/* Take up some space */}
                {/* <div style={{height:"500px"}}></div> */}
            </div>
        </div>
    );
}